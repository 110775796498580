<template>
  <div>
    <!-- <span v-for="(field, index) in fields" :key="index"> -->
      <div class="title text-capitalize">{{ drugname + " PK Parameters"}}</div>
      <v-layout row pa-2>
        <v-flex xs12 sm4 lg4 xl3 pr-4 v-for="(field, index) in fields" :key="index">
          <component
            v-if="field.type === 'radio'"
            v-model="pkParameters[field.key]"
            :is="field.type"
            :append-text="field.unit"
            :label="field.label"
            :disabled="field.isReadonly"
            :field-key="field.key"
            :items="field.items"
          />
          <component
            v-else
            :is="field.type"
            :value="roundedValue(field.key)"
            :append-text="field.unit"
            :label="field.label"
            :disabled="field.isReadonly"
          />
        </v-flex>
      </v-layout>
    <!-- </span> -->
  </div>
</template>

<script> 
import { get } from "lodash";
import Number from "@/new-components/ui/NumberInput";
import NumberInput from "@/new-components/ui/NumberInput";
import Dropdown from "@/new-components/ui/Dropdown";
import Radio from "@/new-components/ui/Radio";

export default {
  name: "PKParameters",
  components: { Number,NumberInput,Dropdown,Radio },
  props: {
    schema: { type: Array, required: true },
    pkParameters: { type: Object, required: true },
    drugname: { type: String, required: true}
  },

  computed: {
  },
   created() {
  },
  data() {
    return {
      fields: [],
      rounded: []
    };
  },
  mounted() {
    this.fields = this.schema;
  },
  methods: {
      roundedValue(key) {
        return get(this.pkParameters, key, 0)
    },
  }
};
</script>
