<template>
  <div>
    <v-layout row pa-2>
      <v-flex
        xs12
        sm6
        lg4
        xl3
        pr-4
        v-for="(field, index) in fields"
        :key="index"
      >
       <component
            v-if="field.type === 'radio'"
            v-model="successMetrics[field.key]"
            :is="field.type"
            :append-text="field.unit"
            :label="field.label"
            :disabled="field.isReadonly"
            :field-key="field.key"
            :items="field.items"
          />
        <component
          v-else
          :is="field.type"
          :value="successMetricsValue(field.key)"
          :append-text="field.unit"
          :label="field.label"
          :disabled="field.isReadonly"
        />
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
// import { Main } from '@/services/modules';
import { get } from 'lodash';
import Number from '@/new-components/ui/NumberInput';
import NumberInput from "@/new-components/ui/NumberInput";
import Dropdown from "@/new-components/ui/Dropdown";
import Radio from "@/new-components/ui/Radio";

export default {
  name: 'Metrics',

  components: { Number,NumberInput,Dropdown,Radio },

  props: {
    schema: { type: Array, required: true },
    successMetrics: { type: Object, required: true },
    drugname: { type: String, required: true, default: ''}
  },
  data() {
    return {
      fields: [],
      rounded: [],
    };
  },
  mounted() {
    this.fields = this.schema;
  },

  methods: {
    successMetricsValue(key) {
      return get(this.successMetrics, key);
    },
  },
};
</script>
